.sticky-school {
    left: 0;
}

.sticky-date {
    left: 70px;
}

.sticky-year {
    left: 173px;
}

.sticky-parent {
    left: 272px;
}
