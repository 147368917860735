.sticky-col, .sticky-header-multiline, .sticky-header-row {
    position: sticky;
    position: -webkit-sticky;
    background-color: #FFFFFF;

    &.sticky-header {
        z-index: 10;
    }
}

.sticky-header-multiline {
    tr {
        position: sticky;
        position: -webkit-sticky;

        &:nth-child(2) {
            top: 57px;
        }

        &:nth-child(3) {
            top: 114px;
        }
    }
}
